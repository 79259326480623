:root {
  --bg: #08080A;
  --normal: #18181B;
  --border: #27272A;
  --grey: #909098;
  --green: #8dd44c;
  --white: #FAFAFA;
  --radius: 6px;
  --width: minmax(50vw, 15rem);
}

* {
  box-sizing: border-box;
}



@font-face {
  font-family: ExtraBold;
  src: url(./fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Bold;
  src: url(./fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Medium;
  src: url(./fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Light;
  src: url(./fonts/Inter-Light.ttf);
}


body{
  background: #08080A;
  margin: 0;
  overflow-x: hidden;
  max-width: 100vw;
}

#tsparticles canvas{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

body {
  width: 100%;
  min-height: 100%;
}



html::-webkit-scrollbar-thumb {
  background-color: var(--grey);
}

html::-webkit-scrollbar {
  width: 6px;
  background-color: var(--normal);
}

a, ul, li, h1, h2, h3, h4, p {
  margin: 0;
  font-weight: normal;
  padding: 0;
  text-decoration: none;

}

header {
  margin-bottom: 2rem;
  color: var(--white);
  font-family: Bold;
}

h1 {
  color:var(--white);
  font-family: Bold;
}

h2 {
  color:var(--white);
  font-family: Medium;
}

h3 {
  color: var(--white);
  font-family: Light;
}

p {
  color: var(--grey);
  font-family: Light;
}




.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  height: 3rem;
  width: 100%;
  transition: max-height ease-in-out 150ms;
  background-color: rgba(8, 8, 10, 0.95);
  backdrop-filter: blur(10px);
  z-index: 998;
  overflow: hidden;
  border-bottom: thin solid var(--border);
}



.nav-item:not(.nav-title) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2.5rem;
  cursor: pointer;
}

.nav-title {
  margin: 0 3.5rem 0 0;
}

.nav-item img{
  margin: 0;
  padding: 0;
  height: 15px;
}

.nav-item svg {
  margin: 0;
  padding: 0;
  margin-top: 3px;
  margin-left: 0;
  transition: all ease-in-out 150ms;
}

.rotated {
  
  rotate: 180deg;
}

.nav-item:not(.nav-title) span {
  color: var(--white);
  margin: 0 2px;
  font-size: 14px;
  font-family: Medium;
}


.nav-title span{
  color: var(--white);
  margin-left: 7px;
  font-size: 15px;
  font-family: Bold;
}

.contacts {
  margin-left: auto;
}

.contacts a {
  margin-left: 1rem;
}



.navbar {
  display: flex;
  align-items: center;
  width: 90%;


  max-width: 90rem;
  padding: 0 15rem;
  min-height: 3rem;
}

.products {
  display: flex;
  flex-direction: row;
  width: 90%;

  max-width: 90rem;
  margin: 0 auto;
}

.product-games {
  max-height: 32rem;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
}


.product-games::-webkit-scrollbar-thumb {
  background-color: var(--grey);
}

.product-games::-webkit-scrollbar {
  width: 6px;

}

.product-games a {
  display: flex;
  align-items: center;
  border-radius: var(--radius);
  padding: 5px;
  width: fit-content;
  margin-bottom: 1rem;
  border: thin solid var(--border);
  background-color: var(--normal);
  color: var(--white);
  font-family: Medium;
}

.product-games a svg {
  margin-right: 5px;
}


.product-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: fit-content;
  border-bottom: thin solid var(--border);
  overflow: hidden;
  margin-bottom: 1rem;
  min-width: 13rem;
  max-width: 13rem;
  transition: max-height ease-in-out 150ms;
}

.other-list {
  border-bottom: 0;
}


.product-list-title {
  display: flex;
  padding: 0 5px;
  align-items: center;
  margin-bottom: 1rem;
  min-width: 12rem;
  max-width: 12rem;
  font-family: Medium;
  color: var(--white);
}

.product-list-title svg {
  margin-right: 5px;
}

.plus-icon {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
  height: 13px;
  width: 13px;
}

.plus {
  position: absolute;
  background-color: var(--white);
  width: 1px;
  transition: height ease-in-out 150ms;
}

.product-list-closed .plus {
  height: 13px;
}

.product-list-open .plus {
  height: 1px;
}

.minus {
  position: absolute;
  height: 1px;
  background-color: var(--white);
  width: 13px;
}

.product-list-games {
  color: var(--white);
  text-align: left;
  padding: 0 5px;
}

.product-list-games p {
  margin-bottom: 1rem;
  font-family: Light;
  font-size: 14px;
}


.selected-game {
  color: var(--green);
  font-size: 15px; 
  transition: all ease-in-out 150ms;
  font-family: Medium;
}


.products .product-list-games p:hover {
  color: var(--green);
}

.products .product-games p {
  cursor: pointer;
}


.product-cheats {
  width: 25rem;
  margin-bottom: 2rem;
  margin-left: 25%;
}

.product-cheats-empty {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;
  width: 25rem;
  margin-left: 25%;
}

.pick {
  color: var(--white);
  text-align: center;
  font-size: 25px;
  font-family: Medium;
  z-index: 2;
  margin-bottom: 3.5rem;
}

.pick-background {
  position: absolute;
  z-index: 1;
  color: var(--normal);
  opacity: 0.4;
}

.product-cheats-title {
  border-bottom: var(--border) thin solid;
  margin: 1rem 0;

}

.product-cheats-title h3{
  margin-top: 1rem;
  margin-bottom: 10px;
}

.product-cheats-title h4 {
  margin-bottom: 1rem;
}


.product-cheats-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-height: 10rem;
}

.product-cheats-list li {
  font-family: Light;
  color: var(--white);
  list-style-type: none;
}

.product-cheats-list a:first-child {
  margin: 0;
}

.product-cheats-list a:not(:first-child){
  margin: 0 0 0 1rem;
}


.product-list-popular {
  
}

.nav-searchbar {
  display: flex;
  align-items: center;
  color: var(--white);
  height: 2rem;
  width: calc(13rem - 10px);
  padding: 0 5px;
  margin-bottom: 1.5rem;
  border-bottom: thin solid var(--border);
}

.nav-searchbar input {
  background: transparent;
  border: 0;
  height: 2rem;
  color: var(--white);
  outline: none;
  width: 100%;
  font-size: 15px;
}


.nav-searchbar svg {
  margin-right: 3px;
}


.product-button {
  width: 100%;
  height: 3.5rem;
  margin-top: 1rem;
  background-color: var(--normal);
  border: thin solid var(--border);
  color: var(--white);
  font-family: Bold;
  border-radius: .5rem;
  cursor: pointer;
}

.mobile-nav-burger {
  position: fixed;
  display: none;
  right: 90%;
  z-index: 999;
  top: 15px;
  flex-direction: column;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  align-items: center;
}

.nav-line {
  background-color: var(--white);
  height: 2px;
  margin-bottom: 6px;
  width: 30px;
}

.mobile-nav a {
  display: flex;
  align-items: center;
  width: 90%;
  height: 4rem;
  color: var(--white);

  font-family: Light;
  border-bottom: var(--border) thin solid;
}

.mobile-nav a:last-child {
  border-bottom: none;

}

.mobile-products {
  display: none;
}

.nav-item-mobile-store {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 4rem;
  color: var(--white);
  font-family: Light;
  text-align: left;
  border-bottom: var(--border) thin solid;
}

.nav-item-mobile-store svg {
  transform: translateX(-3px);
}

.nav-item-mobile {
width: 100%;
min-height: 1rem;
margin: 0;
text-align: left;
}

.normal-mobile {
  rotate: -90deg;
}

.mobile-product-games::-webkit-scrollbar-thumb {
  background-color: var(--white);
    border-radius: var(--radius);
}

.mobile-product-games::-webkit-scrollbar {
  width: 6px;
}


@media  (max-width: 1200px){
  
  .nav-item:not(.nav-title) {

    margin: 0 2vw;

  }
  
  .nav-title {
    margin: 0 3vw 0 0;
  }
  
}

@media  (max-width: 800px){
  
  .nav-item:not(.nav-title) {

    display: none;

  }
  .nav-title {
    margin: 0;
  }

  .contacts {
    display: none;
  }
  
  .mobile-nav-burger {
    display: flex;
  }

  .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-nav-blur {
    display: flex;
    position: fixed;
    left: 0%;
    top: 3rem;
    z-index: 998;
    width: 100%;
    background-color: rgba(8, 8, 10, 0.0);
    height: 100%;
    transition: all ease-in-out 300ms;
    pointer-events: none;
  }

  .mobile-nav-blur-active {
    pointer-events: all;
    background-color: rgba(8, 8, 10, 0.80);
  }

  .mobile-nav {
    display: flex;
    position: fixed;
    left: -100%;
    top: 3rem;
    z-index: 998;
    background-color: var(--bg);
    width: 50vw;
    max-width: 15rem;
    border-right: thin solid var(--border);
    border-bottom: thin solid var(--border);
    max-height: calc(32rem + 4px);
    border-bottom-right-radius: 10px;
    overflow-y: overlay;
    transition: all ease-in-out 300ms;
  }
  
  .mobile-nav-blur-active .mobile-nav{
    left: 0%;
  }

  .mobile-nav-noborder {
    border-right: none;
    
    border-bottom-right-radius: 0;
  }

  .mobile-products {
    display: none;
    position: fixed;
    width: 50vw;
    max-width: 15rem;
    left: -100%;
    transition: all ease-in-out 300ms;
  }

  .mobile-nav-blur-active .mobile-products{
    display: flex;
  }

  .mobile-products-active {
    left: min(15rem, 50vw);
  }

  .mobile-product-games {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--bg);
    overflow: auto;
    max-height: calc(32rem + 4px);
    border-bottom-right-radius: 10px;
    border-right: thin solid var(--border);
    border-bottom: thin solid var(--border);
  }


  .mobile-product-games a {
    display: flex;
    align-items: center;
    width: 90%;
    height: 4rem;
    min-height: calc(4rem - 1px);
    border-bottom: var(--border) thin solid;
  }

  .mobile-product-games a p {
    color: var(--white);
    font-family: Light;
    font-size: 14px;
    width: 100%;

  }

  .mobile-product-games a:last-child{
    border-bottom: none;
  }



}

.test {
  position: absolute;
  top: 10rem;
}

main {
  margin-top: 3.5rem;
}


.hero {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: end;
  width: 90%;
  margin: 0 auto;
  min-height: 44rem;
  max-width: 90rem;
}

.hero-header {
  max-width: 29rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  margin-bottom: 10rem;
}

.hero-title-text {
  font-size: 7rem;
  font-family: ExtraBold;
  margin: 0;
  padding: 0;
}

.hero-title-text:not(.title-hood) {
  color: var(--white);
  margin: 0;
  padding: 0;
}


.title-hood {
  margin: 0;
  padding: 0;
  color: var(--green);
}


.title-hood img {
  height: 5.1rem;
  margin-right: 10px;
}

.hero-description {
  font-size: 18px;
  margin: 1rem 0;
  margin-bottom: 1rem;
}

.hero-nav-buttons {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: 1rem;
}


.hero-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 5rem;
  font-family: bold;
  font-size: 17px;
  cursor: pointer;
}

.hero-btn img {
  width: 100%;
  height: 100%;
}

.hero-btn h3 {
  position: absolute;
  color: var(--bg);
  margin-right: 10px;
}

.btn-odd h3 {
  color: var(--white);
  margin-left: 10px;
  margin-right: 0;
}




.hero-store-bg {
  height: 5rem;
  z-index: 4;
  width: 9rem;
  border-radius: var(--radius);
  -ms-transform: skew(15deg);
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
  background-color: var(--green);
  position: absolute;
}

.hero-store-button {
  z-index: 5;
  text-align: left;
  margin-left: 5rem;
  background-color: var(--green);
}

.hero-forums-bg {
  height: calc(5rem - 2px);
  z-index: 4;
  width: 9rem;
  border-radius: var(--radius);
  -ms-transform: skew(15deg);
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
  border: var(--green) thin solid;
  position: absolute;
  right: 0;
}

.store-fix {
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-transform: skew(15deg);
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
  margin-top: 1px;
  height: calc(5rem - 2px);
  width: 9rem;
  z-index: 99;
  position: absolute;
  pointer-events: none;
  left: 2.5rem;
  text-align: center;
  font-family: bold;
  font-size: 17px;
  color: var(--bg);
}



.forums-fix {
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-transform: skew(15deg);
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
  margin-top: 1px;
  height: calc(5rem - 2px);
  width: 9rem;
  background-color: var(--bg);
  z-index: 99;
  position: absolute;
  pointer-events: none;
  right: 2.5rem;
  text-align: center;
  font-family: bold;
  font-size: 17px;
  color: var(--white);
}


.hero-forums-button {
  text-align: right;
  color: var(--white);
  margin-right: 5rem;
  background: var(--bg);
}


.hero-nav-buttons-link {
  margin: 1rem 0;
  cursor: pointer;
}


.hero-games-showcase {
  position: absolute;
  top: -3.5rem;
  right: -15rem;
  width: 60rem;
  height: 40rem;
}


.slider-container {
  display: block;
  z-index: 99;
  height: 100%;
  width: 100%;
  mask: linear-gradient(90deg, transparent, white 15%, white 85%, transparent);
}



.hero-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  margin-top: 30rem;
}

.hero-showcase {
  position: absolute;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  mask-image: url(images/mask7.webp);
  transition: all ease-in-out 300ms;
}

.hero-showcase-apex {
  animation: .3s fadeIn ease-in-out;
  background-image: url(images/backgrounds/apexbackground.webp);
  transition: all ease-in-out 300ms;
}


.hero-showcase-tarkov {
  animation: .3s fadeIn ease-in-out;
  background-image: url(images/backgrounds/tarkovbackground.webp);
  transition: all ease-in-out 300ms;
}

.hero-showcase-fortnite {
  animation: .3s fadeIn ease-in-out;
  background-image: url(images/backgrounds/fortnitebackground.webp);
  transition: all ease-in-out 300ms;
}

.hero-showcase-val {
  animation: .3s fadeIn ease-in-out;
  background-image: url(images/backgrounds/valbackground.webp);
  transition: all ease-in-out 300ms;
}

.hero-showcase-finals {
  animation: .3s fadeIn ease-in-out;
  background-image: url(images/backgrounds/finalsbackground.webp);
  transition: all ease-in-out 300ms;
}

.hero-showcase-character {
  position: absolute;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 5rem;

  margin: 0 auto;
  height: 35rem;
  animation: .5s slideIn ease-in-out;
}

.hero-showcase-inactive {
  display: none;
}


@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.4;

  }
  to {
    opacity: 1;

  }
}

.hero-item {
  font-size: 40px;
  display: flex;
  justify-content: center;
  height: 11rem;
  align-items: center;
  border-radius: var(--radius);
  width: 100%;
  font-family: sans-serif;
}


.hero-item img {
  object-fit: cover;
  transition: all ease-in-out 300ms;
  max-height: 6rem;
  max-width: 6rem;
  opacity: 0.5;
}


.slick-center img {
  object-fit: cover;
  opacity: 1;
  max-height: 9rem;
  max-width: 15rem;
}



@media  (max-width: 1500px){

  .hero-games-showcase{
    display: none;
  }


  .hero-games {
    display: none;
  }

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    max-width: 90rem;
  }

  .hero-header {
    width: 100%;

  }
  .hero-title-text {
    text-align: center;
  }
  .hero-description {
    text-align: center;
  }

  .hero-nav-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }


}

@media  (max-width: 500px){
  .hero-title-text {
    font-size: 6rem;
    font-family: ExtraBold;
    margin: 0;
    padding: 0;
  }

  .title-hood img {
    height: 4.5rem;
    margin-right: 10px;
  }

}


@media  (max-width: 450px){
  .hero-title-text {
    font-size: 18vw;
    font-family: ExtraBold;
    margin: 0;
    padding: 0;
  }

  .title-hood img {
    height: 13vw;
    margin-right: 10px;
  }

}




@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;

}

.logos:hover .logos-slide {
  animation-play-state: paused;
}


.logos-slide {
  display: flex;
  flex-direction: row;

  max-height: 40px;
  animation: 15s slide infinite linear;
}

.logo-item {
  display: flex;
  align-items: center;
  margin-right: 4rem;
  transform-origin: center;
  transition: all ease-in-out 300ms;
}
.logo-item h1 {
  font-size: 16px;
}

.logos-slide img {
  height: 40px;
  width: 40px;
  bottom: 0;
  margin: 0 10px;
}

.logo-item:hover {
  transition: all ease-in-out 300ms;
  transform: scale(1.1);
}

@media  (min-width: 3500px){
  
  .logos {
    max-width: 130rem;
    margin: 0 auto;
    mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
  }
}


.hood-stats {
  width: 90%;
  margin: 5rem auto 10rem auto;
  max-width: 90rem;
  display: flex;
  justify-content: space-around;
}

.hood-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 15rem;
  margin-top: 5rem;
}

.hood-stat h1 {
  font-size: 4rem;
}

.hood-stat h4 {
  font-size: 1rem;
}

@media  (max-width: 800px){
  .hood-stats {
    width: 90%;
    margin: 10rem auto;
    max-width: 90rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
  }
}

.popular-cheats {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20rem auto 10rem auto;
  max-width: 78rem;
}


.popular-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.popular-header img {
  height: 2.5rem;
  margin-right: 15px;
}

.popular-title {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  color: var(--white);
  font-size: 1.7rem;
  white-space: nowrap;
  font-family: Bold;
  justify-content: center;
}


.popular-title span {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  color: var(--green);
}

.popular-title svg {
  margin-right: 5px;
}



.popular-header button {
  border: thin solid var(--border);
  background: var(--bg);
  padding: 1rem 2rem;
  border-radius: var(--radius);
  color: var(--white);
  font-family: Bold;
  margin-right: 10px;
}

.popular-header h1 {
  display: inline-flex;
  margin-left: 10px;
  align-items: center;
}

.popular-header svg {
  margin-right: 5px;
}

.popular-mobile-btn {
  border: thin solid var(--border);
  background: var(--bg);
  padding: 1rem 2rem;
  border-radius: var(--radius);
  color: var(--white);
  font-family: Bold;
  display: none;
}


@media  (max-width: 1100px){
  .popular-header {
    justify-content: center;
  }

  .popular-header button {
    display: none;
  }

  .popular-mobile-btn {
    display: flex;
    margin: 1rem auto;
  }
}

.popular-cheat-row {
  margin-bottom: 7rem;
}

.cheats {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 3rem;
}



.cheat-card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 18rem;
  max-height: 18rem;
  width: 18rem;
  min-width: 18rem;
  height: 18rem;
  border: thin solid var(--border);
  border-radius: var(--radius);
  background-color: var(--bg);
  margin: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all ease-in-out 150ms;
}

.cheat-link-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 18rem;
  max-height: 15.3rem;
  width: 18rem;
  height: 15.3rem;
}

.cheat-tags-slider-mask {
  width: 18rem;
  mask: linear-gradient(
    270deg,
    transparent 3%,
    white 10%,
    white 90%,
    transparent 97%
  );
  transition: ease-in-out all 150ms;
}

.cheat-tags-slider {
  width: calc(18rem - 20px);
  overflow: hidden;
  margin: 10px 0;
  margin-left: 10px;
  cursor: move;
}

.cheat-tags {
  display: flex;
  transition: all ease-in-out 300ms;
  overflow-x: hidden;
}

.cheat-tag {
  user-select: none;
  padding: 4px 8px;
  margin-right: 9px;
  margin-left: 2px;
  pointer-events: none;
  background-color: var(--green);
  color: var(--bg);
  font-size: 13px;
  border-radius: 4px;
  font-family: Bold;
  white-space: nowrap;
}


.popular {
  background-color: yellow;
}

.undetected {
  background-color: var(--green);
}

.detected {
  background-color: rgb(255, 38, 0);
}

.tested {
  background-color: #00A3FF;
}

.trusted {
  background-color: #00ffea;
}

.new {
  background-color: #3078e4;
}

.trending {
  background-color: #FF8C05;
}

.risky {
  background-color: orangered;
}

.no {
  background-color: rgb(255, 38, 0);
}

.reccomended {
  background-color: purple;
}

.cheat-image {
  width: calc(100% - 20px);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  margin-bottom: 10px;
  overflow: hidden;
  transition: all ease-in-out 200ms;
}



.cheat-card:hover {
  border: thin solid rgba(255, 255, 255, 0.4);
}

.cheat-image img{
  width: 100%;

}

.cheat-logo {
  position: absolute;
  max-height: 100px;
  object-fit: contain;
  transition: all ease-in-out 200ms;
}

.cheat-info {
  display: flex;
  justify-content: space-between;
  height: 5rem;
  border-top: thin solid var(--border);
  width: 100%;
  padding: 10px;
  margin-top: auto;
  border-radius: var(--radius);
}

.cheat-info-col {
  display: flex;
  flex-direction: column;
}

.cheat-name {
  height: fit-content
}

.cheat-name h2 {
  font-size: 17px;
}

.cheat-range {
  margin-top: auto;
}

.cheat-range h2 {
  font-size: 16px;
  
  display: inline;
}


.cheat-stars {
  display: flex;
  align-items: end;
  font-family: Medium;
  margin-top: auto;
}

.cheat-stars h3 {
  font-size: 16px;
  margin-right: 5px;
}

.cheat-stars svg {
  color: yellow;
  height: 20px;
  width: 20px;
}



.provider-gameplay {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 15rem auto 0 auto;
  max-width: 90rem;

}

.provider-container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  justify-content: center;
  width: 100%;
}



.provider-media-container {
  margin-right: 3rem;
  width: 100%;
  max-width: 35rem;

}

.provider-media {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35rem;
  height: calc(35rem * 0.5625);
  border: thin solid var(--border);
  border-radius: var(--radius);
  background-color: var(--bg);
  overflow: hidden;
  aspect-ratio: 16/9;
}

.provider-media iframe {
  width: 100%;
  height: 100%;
}

.provider-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  font-family: Bold;
  color: var(--white);
}

.provider-nav svg {
  color: var(--white);
}

.provider-nav-item {
  display: none;
  user-select: none;
}

.provider-nav-item-active {
  display: flex;
}


.provider-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 57%;

  height: 20rem;
}

.provider-games {
  width: 100%;
}
.provider-games h1 {
  font-size: 1rem;
}

.provider-games-list {
  display: flex;
  flex-wrap: wrap;

}

.provider-games-list img {
  cursor: pointer;
  height: 40px;
  margin-right: 5px;
  transition: ease-in-out 300ms all;
}



.provider-games-list svg, .provider-games-list img {
  margin-right: 1rem;
  margin-top: .5rem;
}

.provider-text {
  margin-top: 2.5rem;
  color: var(--grey);
  max-height: 7rem;
  margin-bottom: 10px;
  font-family: Light;
  overflow: auto;
  mask: linear-gradient(180deg, transparent, white 5%, white 95%, transparent);
}

.provider-text::-webkit-scrollbar-thumb {
  background-color: var(--normal);
}

.provider-text::-webkit-scrollbar {
  width: 6px;

}

.provider-view-container {
  margin-top: auto;
}

.provider-view-inactive {
  opacity: 0.3;
  transition: all ease-in-out 300ms;
}

.provider-view {
  padding: 1.5rem 2.5rem;
  border-radius: var(--radius);
  background: var(--bg);
  border: thin solid var(--border);
  color: var(--white);
  transition: all ease-in-out 300ms;
  font-family: Bold;
  pointer-events: none;
}

.provider-view-container:hover button:not(.provider-view-inactive) {
  background-color: var(--normal);
}

.link-disable {
  pointer-events: none;
  user-select: none;
}

.provider-text h4 {
  font-size: 1re;
}

.link-enable {
  pointer-events: all;
  user-select:all;
}



@media  (max-width: 1100px){
  .provider-media {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 35rem;
    height: calc(100% * 0.5625);;
    max-height: calc(35rem * 0.5625);
  }
  


  .provider-gameplay header {
  text-align: center;
  }
  .provider-container {
    flex-direction: column;
    align-items: center;
  }
  .provider-media-container {
    margin-right: 0;
  }

  .provider-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: 35rem;
    margin-top: 2rem;
    min-width: 0;
  }
  .provider-view-container {
    margin: auto;
    margin-top: 2rem;
  }

}

.game-banner {
  display: flex;
  margin-top: 10rem;
  width: 100%;
  background-color: var(--bg);
  border-top: thin solid var(--border);
  border-bottom: thin solid var(--border);
  height: 33rem;
}

.banner-top {
  margin-top: 20rem;
}


.banner-container {
  display: flex;

  justify-content: space-between;
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: 135rem;
}

.game-image {
  display: flex;
  align-items: center;
  mask: linear-gradient(90deg, transparent, white 50%, white 100%, transparent);
  min-width: calc(50% - 3rem);
  min-height: 100%;
  overflow: hidden;
}

.game-image-left {
  mask: linear-gradient(90deg, transparent, white 0%, white 50%, transparent);
  justify-content: right;
}

.game-image img {
  object-fit: cover;
  min-width: 100%;
  min-height: 145%;
}

.game-info-right {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin-top: 3rem;
  margin-right: 3rem;
}

.game-info-left {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 3rem;
  margin-left: 3rem;
}

.game-info-left h1 {
  text-align: left;
}

.game-info-right h1 {
  text-align: right;
}


.game-info-left h4 {
  margin-top: 2rem;
  margin-right: 2rem;
}


.game-info-right h4 {
  margin-top: 2rem;
  margin-left: 2rem;
  text-align: right;
}

.game-info button {
  background: var(--bg);
  color: var(--white);
  font-family: BOld;
  border: thin solid var(--border);
  margin-top: auto;
  padding: 1.5rem 2.5rem;
  border-radius: var(--radius);
}

.game-info button:hover {
  background-color: var(--normal);
}

.game-info a {
  margin-top: auto;
  margin-bottom: 5rem;
}


.game-info-right a {
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 5rem;
}



@media  (max-width: 1100px){
 
  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .game-banner {
    min-height: 40rem;
    height: fit-content;
  }

  .game-image {
    mask: linear-gradient(180deg, transparent, white 50%, white 100%, transparent);
  }
  
  .game-image-left {
    mask: linear-gradient(180deg, transparent, white 0%, white 50%, transparent);
  }
  

  .game-info-left {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-bottom: 2rem;
  }

  .game-info-right a {
    margin-top: auto;
    margin-left: 0;
    
    margin-bottom: 5rem;
  }

  .game-info-left h4{
    text-align: center;
    margin-bottom: 2rem;
  }

  .game-info-right h4{
    text-align: center;
    margin-bottom: 2rem;
  }

  .game-info-right {
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-right: 0;
  }

  .game-info-right h4 {
    margin-left: 0;
  }

  .game-info-left h4 {
    margin-right: 0;
  }

  .game-info-left h1 {

    text-align: center;

  }
  
  .game-info-right h1 {

    text-align: center;

  }


  .game-image {
    display: flex;
    width: 100%;
    min-height: 0;
    max-height: 30rem;
    min-width: 100%;
    overflow: hidden;

  }

  .game-image {
    margin-top: auto;
  }
  .game-image-left {
    margin-top: 0;
    margin-bottom: auto;
  }

  .game-image img {
    object-fit: cover;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
  }
  
}
.choose-us {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: 15rem auto 0 auto;
  max-width: 70rem;
  margin-top: 20rem;
}

.choose-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.choose-card {
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  border: thin solid var(--border);
  margin: 10px;
  height: 14rem;
  background-color: var(--bg);
  width: 20rem;
  overflow: hidden;

}

.choose-card:hover h1 {
  transform: translateX(-5rem);
  color: var(--green);
  opacity: 1;
}

.choose-card h1 {
  color: var(--grey);
  opacity: 0.2;
  margin-top: 2.5rem;
  white-space: nowrap;
  text-align: right;
  margin-right: 10px;
  font-size: 3rem;
  font-family: Bold;
  transition: all ease-in-out 300ms;
}

.choose-card-desc {
  margin: 1rem;
  margin-top: auto;

}

.choose-card-desc h4 {
  margin-top: 5px;
}

.reviews {
  margin-top: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 90rem;
  margin: 20rem auto auto auto;
}

.reviews-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 70rem;
}

.review-card {
  max-width: 19rem;
  background-color: var(--bg);
  padding: 1rem;
  border-radius: var(--radius);
  margin: 10px;
  border: thin solid var(--border);
}


.review-card-author {
  display: flex;
  width: 100%;
  margin-top: 2rem;
}

.review-card-author svg {
  margin-right: 10px;
  color: var(--white)
}


.review-card-author p {
  margin-top: 5px;
}



.games-container {
  display: flex;
  margin-top: 5rem;
}

.breadcrumbs {
  position: absolute;
  width: 90%;
  max-width: 90rem;
  top: 5rem;
  color: var(--white);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.breadcrumbs a {
  font-family: Light;
  color: var(--green);
}

.breadcrumbs svg {
  transform: translateX(-1px);
  rotate: -90deg;
  margin: 0 3px;
}

.cheat-header {
  text-align: center;
  color: var(--white);
  margin-top: 5rem;
  font-size: 1.7rem;
  width: 100%;
  font-family: Bold;
}

.cheats-page {
  display: flex;
  position: relative;
  justify-content: center;
  max-width: 90rem;
  width: 90%;
  margin: 3rem auto 0 auto;
}

.filter {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: fit-content;
  border-radius: var(--radius);
  margin-top: 10px;
  margin-right: 2rem;
  background-color: var(--bg);
  border: thin solid var(--border);
  transition: all ease-in-out 250ms;
}

.mobile-filter {
  border-bottom: var(--border) thin solid;
  height: 2.7rem;
  max-height: 2.7rem;
  overflow: hidden;
  top: calc(3rem + 1px);
  position: fixed;
  background-color: rgba(8, 8, 10, 0.95);
  width: 100%;
  display: none;
  flex-direction: column;
  z-index: 997;
  font-family: Light;
  color: var(--white);
  backdrop-filter: blur(3px);
  transition: all ease-in-out 300ms;
}

.mobile-filter-header {
  display: flex;
  width: 90%;
  padding: 0 5%;
  min-height: 2.7rem;
  height: 2.7rem;
  align-items: center;
  justify-content: end;
}

.mobile-filter-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;

}

.mobile-filter-active .mobile-filter-btn svg {
  rotate: 180deg;

}

.mobile-filter-active {
  max-height: 25rem;
  height: 25rem;
}



.mobile-filter-btn svg {
  margin-left: 5px;
  margin-top: 2px;
  transition: all ease-in-out 150ms;
}

.mobile-filter-body {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 0 5%;

}

.mobile-filter-body::-webkit-scrollbar-thumb {
  background-color: var(--normal);
}

.mobile-filter-body::-webkit-scrollbar {
  width: 6px;

}


.mobile-searchbar {
  display: flex;
  align-items: center;
  color: var(--white);
  height: 2rem;
  width: 100%;
}

.mobile-searchbar input {
  background: transparent;
  border: 0;
  height: 2rem;
  color: var(--white);
  outline: none;
  width: 100%;
  font-size: 15px;
  border-bottom: thin solid var(--border);
}


.mobile-searchbar svg {
  margin-right: 5px;
}

.mobile-tooltip {
  color: var(--white);
  font-family: Light;
  font-size: 14px;
  margin: 20px auto 8px 0;
}


.mobile-minmax {
  display: flex;
  justify-content: start;
  width: 100%;
}

.mobile-minmax .limits input {
  width: 3rem;
  padding: .7rem;
  background: var(--bg);
  border: thin solid var(--border);
  border-radius: var(--radius);
  margin-right: 15px;
  
  color: var(--white);
}

.mobile-sort-btns {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
}

.mobile-sort-btns button {
  margin-right: 1rem;
  max-height: 2rem;
}


.mobile-checkbox-genre {
  color: var(--white);
  font-family: Medium;
  font-size: 14px;
  margin-right: auto;
  margin-top: 1.5rem;
}

.mobile-checkboxes {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.mobile-checkbox-list {
  min-width: fit-content;
  margin-right: 1rem;
}

.mobile-checkbox {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  position: relative;
  -ms-user-select: none;
  user-select: none;
  margin-top: 15px;
  margin-right: auto;
  padding-left: 30px;
}

.mobile-checkbox label{
  color: var(--white);
  font-family: Light;
  font-size: 15px;
  margin-top: 1.5px;
}

.mobile-checkbox input {
  position: absolute;
  left: -2px;
  top: -1px;
  margin-right: auto;
  z-index: 5;

  opacity: 0;

}

.mobile-checkbox input:checked ~ .mobile-checkmark {
  background-color: var(--green);
}

.mobile-checkmark {
  position: absolute;

  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: thin solid var(--border);
  border-radius: 3px;
}

.searchbar {
  display: flex;
  align-items: center;
  color: var(--white);
  height: 2rem;
}

.searchbar svg {
  margin-right: 5px;
}

.tooltip {
  color: var(--white);
  font-family: Light;
  font-size: 12px;
  margin: 15px 0 8px 0;
}


.minmax {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.limits {
  display: flex;
}

.limits input {
  width: 2.5rem;
  padding: .5rem;
  background: var(--bg);
  border: thin solid var(--border);
  border-radius: var(--radius);
  margin-right: 15px;
  color: var(--white);
}

.filter-submit {
  border: thin solid var(--border);
  background-color: var(--normal);
  color: var(--white);
  cursor: pointer;
  width: 5rem;
  padding: .5rem;
  border-radius: var(--radius);
}

.sort-btn {
  border: thin solid var(--border);
  background: var(--bg);
  color: var(--white);
  font-family: Medium;
  display: flex;
  width: fit-content;
  padding: .4rem 1rem;
  border-radius: var(--radius);
  margin-bottom: 10px;
}

.sort-active {
  color :var(--bg);
  background-color: var(--green);
}

.checkbox-genre {
  color: var(--white);
  font-family: Medium;
  font-size: 13px;
  margin-top: 1.5rem;
}

.searchbar input {
  background: var(--bg);
  border: 0;
  color: var(--white);
  outline: none;
  width: 100%;
  border-bottom: thin solid var(--border);
}

.checkbox {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  position: relative;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
  padding-left: 25px;
}

.checkbox label{
  color: var(--white);
  font-family: Light;
  font-size: 15px;

}
.checkbox input {
  position: absolute;
  left: -2px;
  top: -1px;
  z-index: 5;
  opacity: 0;
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--green);
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: thin solid var(--border);
  border-radius: 3px;
}




.cards {
  display: flex;
  max-width: 70rem;

  margin: auto;
  width: 90%;
}


.cheats-full {
  display: flex;
  max-width: 80rem;
  height: fit-content;
  flex-direction: row;

  flex-wrap: wrap;

}


.empty {
  display: none;
}

.cheats-full-empty .empty{
  display: flex;
  margin-bottom: auto;
  width: 100%;
  color: var(--white);
  font-family: Medium;
  margin-top: 1rem;
}

.hints {
  width: 100%;
  margin-left: 10px;
  font-size: 13px;
  color: var(--grey);
  font-family: Light;
}



@media  (max-width: 1400px){
  .cheats-full {
    width: 40rem;
  }
}

@media  (max-width: 1050px){
  .cheats-full {
    width: 20rem;
  }
}

@media  (max-width: 700px){
  .filter {
    display: none;
  }
  .cheats-page {
    margin: 4rem auto 0 auto;
  }



  .cheat-header {
    margin-top: 8rem;
  }
  
  .mobile-filter {
    display: flex;
  }

  
  
  .hints {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}


.cheat-product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cheat-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  max-width: 90rem;
  width: 90%;
}

.product-container {
  display: flex;
  width: 100%;

  border-radius: var(--radius);
  border: thin solid var(--border)
}

.product-main-col {
  min-width: none;
  max-width: 75%;
  padding: 20px 0 20px 20px;
}

.product-info-container {
  display: flex;
  overflow: hidden;
  height: fit-content;
  padding-bottom: 20px;
  border-bottom: thin solid var(--border);
}

.product-info-container img {
  object-fit: cover;
  min-height: 100%;
  border-radius: var(--radius);
  aspect-ratio: 1/1;
  width: 15rem;
  margin-right: 20px;
}

.product-info {
  height: 100%;
  max-height: 100%;
}

.product-info h1 {
  font-size: 30px;
  margin-bottom: 1rem;
}

.product-info span {
  display: flex;
  margin-bottom: 4px;
}

.product-info span h2 {
  font-size: 16px;
}

.product-info span h3{
  font-size: 16px;
  margin-left: 5px;
}


.product-description {
  margin-top: 10px;
  overflow-y: auto;
}

.product-tabs-container {
  width: 100%;
  margin-top: 25px;
}

.product-tabs {
  display: flex;
  width: 100%;
}

.product-tabs h2 {
  font-size: 16px;
  cursor: pointer;
  margin-right: 5rem;
  transition: all ease-in-out 150ms;
}

.product-tabs .active-tab{
  color: var(--green);
}

.product-tabs h2:hover {
  color: var(--green);
}




.product-description::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: var(--radius);
}

.product-description::-webkit-scrollbar {
  width: 8px;
}

.product-secondary-col {
  width: 25%;
  min-width: 17rem;
  padding: 20px;
}

.product-checkout-container {
  position: relative;
  height: 100%;
  padding: 0 0 0 20px;
  border-left: thin solid var(--border);
  width: 100%;
}

.product-checkout-container-mobile {
  padding: 0 20px;
  border: thin solid var(--border);
  width: 100%;
  max-width: 40rem;
  margin-top: 2rem;
  border-radius: var(--radius);
  display: none;
}

.product-checkout {
  height: fit-content;
  position: absolute;
  width: 100%;
  padding-right: 20px;
}

.product-tab-container {
  margin-top: 25px;
  border-radius: var(--radius);
  border: thin solid var(--border)
}

.checkout-item {
  width: 100%;
  border-radius: var(--radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: thin solid var(--border);
  height: 3.5rem;
  margin-top: 15px;
  cursor: pointer;
  padding: 15px;
}

.checkout-item-active {
  background-color: var(--normal);
}

.checkout-item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

}

.checkout-item-info h2 {
  font-size: 17px;
}

.checkout-radio {
  min-width: 22px;
  width: 22px;
  margin-right: 10px;
  border: thin solid var(--border);
  border-radius: 50%;
  height: 22px;
  min-height: 22px;
}

.checkout-item-active .checkout-radio {
  position: relative;
  min-width: 22px;
  width: 22px;
  margin-right: 10px;
  border: thin solid var(--white);
  border-radius: 50%;
  height: 22px;
  min-height: 22px;
}

.checkout-item-active .checkout-radio::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  border-radius: 50%;
  bottom: 0;
  margin: auto;
  height: 12px;
  background-color: var(--green);
  right: 0;
  content: '';
}

.checkout-purchase {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  background-color: var(--green);
  width: 100%;
  border-radius: var(--radius);
  margin-top: 1rem;
}

.checkout-purchase h1 {
  color: var(--bg);
  font-size: 18px;
}
.product-checkout-container-mobile {
  padding: 20px;
}
.product-checkout-container-mobile .product-checkout{
  width: 100%;
}

.product-tab{
  display: none;
}


.product-tab-active {
  display: flex;
  padding: 20px;
}

.features-tab {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.feature-list {
  min-width: 33.33%;
  list-style-type: none;
  max-height: fit-content;
  min-height: fit-content;

}

.feature-list-title h2{
  font-size: 16px;
  margin-bottom: 5px;
}

.require-tab {
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}


.require-item {
  display: flex;
  align-items: center;
  min-height: 10rem;
  width: 15rem;
  min-width: 15rem;
  max-width: 30%;
}

.require-item svg {
  color: var(--normal);
  margin-right: 10px;
}

.require-item h2 {
  font-size: 16px;
}

.require-item-title {
  color: var(--grey)
}

.game-tab {
  flex-direction: column;
  align-items: center;
}


.game-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.game-media-inactive {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.game-media iframe {
  height: auto;
  border: thin solid var(--border);
  border-radius: var(--radius);
  aspect-ratio: 16/9;
  width: 100%;
}

.game-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
}

.game-nav-dot {
  width: 5rem;
  height: 10px;
  margin-right: 2rem;
  border-radius: var(--radius);
  background-color: var(--normal);
  transition: all ease-in-out 150ms;
}


.game-nav-dot:not(.dot-active):hover {
  cursor: pointer;
  border: thin solid var(--border)
}

.dot-active {
  background-color: var(--green);
}

.menu-tab {
  gap: 20px;
}

.menu-item {
  width: calc(33.33% - 10px);
  overflow: hidden;
  min-height: 10rem;
  border: thin solid var(--border);
  border-radius: var(--radius);
}

.instruction-tab {
  flex-direction: column;
}

.instruction-item {
  margin-bottom: 2rem;
}

.instruction-item:last-child {
  margin-bottom: 0;
}

.instruction-item h2 {
  font-size: 17px;
}

.instruction-item h3 {
  font-size: 15px;
}


@media  (max-width: 1200px){

  .product-secondary-col {
    display: none;
  }

  .product-main-col {
    min-width: none;
    max-width: 100%;
    padding: 20px;
  }

  .product-checkout-container-mobile {
    display: flex;
  }

}

@media  (max-width: 900px){
.product-tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.product-tabs h2 {
  font-size: 16px;
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 5px;
  transition: all ease-in-out 150ms;
}
}


@media  (max-width: 750px){

  .product-info h1 {
    font-size: 25px;
  }

  .product-tabs h2 {
    font-size: 13px;
  }
  
.product-info-container {
  display: flex;
  height: 20rem;
  padding-bottom: 20px;
  border-bottom: thin solid var(--border);
  max-height: 20rem;
}

.product-info-container img {
  object-fit: cover;
  height: 100%;
  border-radius: var(--radius);
  max-width: 15rem;
  aspect-ratio: 1/1;
  margin-right: 20px;
}

.product-description {
  max-height: 9.25rem;
  height: 9.25rem;
}


}

@media  (max-width: 600px) {
  
  .product-info-container {
    flex-direction: column;
    height: fit-content;
    max-height: fit-content;
  }

  .product-info-container img {
    height: 15rem;
    margin-bottom: 10px;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 0;
  }

  .product-description {
    max-height: none;
    height: fit-content;
  }

}


.other-cheats {
  display: flex;
  flex-direction: column;
  border: thin solid var(--border);
  width: 100%;
  overflow: hidden;
  margin-right: auto;
  max-height: 25rem;
  padding: 15px;
  margin-top: 5rem;
  transition: all ease-in-out 300ms;
  border-radius: var(--radius);
}


.cheat-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12rem;
}

.other-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}



.other-title {
  color: var(--white);
  font-size: 1rem;
  margin-left: 5px;
  font-family: Medium;
}

.other-cheats-row {
  display:flex;
  overflow-x: auto;
  overflow-y: hidden;
  mask: linear-gradient(90deg, transparent, white 1%, white 99%, transparent);
}

.other-cheats-row .cheat-card:first-child {
  margin-left: 5px;
}

.other-cheats-row .cheat-card:last-child {
  margin-right: 5px;
}

.other-cheats-row::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: var(--radius);
}

.other-cheats-row::-webkit-scrollbar {
  height: 8px;
}

@media  (max-width: 700px){
  .other-cheats {
    margin-top: 2rem;
  }

}




.status-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-header {
  color: var(--white);
  font-family: Bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.cheat-status-name {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.cheat-status-name svg {
    color: var(--white);
    opacity: 0;
    transition: all ease-in-out 300ms;
    transform: translateX(-10px);
}

.cheat-status-name:hover svg{
  transform: translateX(4px);
  opacity: 1;
}

.status-filter {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 50rem;
  height: 3rem;
}

.status-searchbar {
  display: flex;
  align-items: center;
  border: thin solid var(--border);
  border-radius: var(--radius);
  width: 100%;
  padding: 0 5px;
}


.status-filter-dropdown {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border: thin solid var(--border);
  width: 14rem;
  min-width: 14rem;
  margin-left: 2rem;
  height: calc(100% - 2px);
  border-radius: var(--radius);
}

.dropdown-text {
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.dropdown-text svg {
  transition: all ease-in-out 150ms;
}

.filter-dropdown-menu-container {
  display: flex;
  justify-content: end;
  width: 90%;
  max-width: 50rem;
  margin-top: 5px;
  height: 1px;
  position: relative;
  overflow: visible;
  transition: all ease-in-out 100ms;
}


.filter-dropdown-menu-container-expand {
  height: 100%;
}


.status-filter-dropdown-menu {
  z-index: 999;
  top: 0;
  width: 14rem;
  border-radius: var(--radius);
  border: thin solid var(--border);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--bg);
  opacity: 0;
  transition: all ease-in-out 100ms;
  transform: translateY(-1rem);
}



.status-filter-dropdown-menu-open {
  transform: translateY(0);
  opacity: 1;
}

.filter-dropdown-games {
  overflow-y: auto;
  max-height: 10rem;
}

.filter-dropdown-games::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: var(--radius);
}

.filter-dropdown-games::-webkit-scrollbar {
  width: 3px;
}

.dropdown-text p {
  margin-left: 10px;
}

.dropdown-text svg {
  margin-right: 10px;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  margin: 7px;
  border-radius: var(--radius);
  color: var(--white);
}


.dropdown-item:hover {
  background-color: var(--border);
}

.dropdown-item-selected {
  background-color: var(--border);
}

.dropdown-item svg {
  display: none;
}

.dropdown-item-selected svg {
  display: flex;
}

.filter-dropdown-searchbar {
  display: flex;
  align-items: center;
  border-bottom: thin solid var(--border);
  width: 100%;
  padding: 2px 5px;
}

.filter-dropdown-searchbar svg {
  color: var(--white);
  margin: 5px;
}

.filter-dropdown-searchbar input {
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--bg);
  color: var(--white);
  font-family: Medium;
}

.status-searchbar svg {
  color: var(--white);
  margin: 5px;
}

.status-searchbar input {
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--bg);
  color: var(--white);
  font-family: Medium;
}




.status-game {
  width: 90%;
  max-width: 50rem;
  margin: 5rem auto;
}


.status-cheats {
  width: 100%;
  background-color: var(--bg);
  border-radius: var(--radius);
  border: thin solid var(--border);
  height: 100%;
}

.cheat-status {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 95%;
  margin: 1rem auto;
  border-bottom: var(--border) thin solid;
  max-height: 2rem;
  min-height: 2rem;
  overflow: hidden;
  transition: all ease-in-out 300ms;
}

.status-game h1{
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 1rem;
}

.status-game img {
  margin-right: 1rem;
  height: 2.5rem;
}

.cheat-status-active {
  max-height: 4rem;
  min-height: 4rem;
}

.cheat-status-top h2 {
  font-size: 16px;
}

.cheat-status-top {
  height: 2rem;
  min-height:2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cheat-status-info {
  display: flex;
  justify-content: space-between;
  height: 2rem;
}

.cheat-status-info h4 {
  margin-top: 5px;
}

.status {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: light;
}

.status p {
  
  margin-right: 5px;
}

.status svg {
  transition: all ease-in-out 150ms;
  margin-top: 2px;
}

.undetected-status {
  color: var(--green)
}

.updating-status {
  color: #00A3FF
}

.risky-status {
  color: orangered
}



.policy-main {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50rem;
  margin: 5rem auto;
}

.policy-header {
  font-size: 1.7rem;
  color: var(--white);
  text-align: center;
  font-family: Bold;
  margin-bottom: 5rem;
}

.policy-nav {
  width: 75%;
  max-width: 45rem;
  display: flex;
  align-items: center;
  border-radius: var(--radius);
  overflow: hidden;
  border: thin solid var(--border);
  justify-content: space-around;
}

.policy-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-family: Medium;
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
  transition: all ease-in-out 150ms;
  position: relative;
}

.policy-nav-item:not(:last-child) {
  border-right: thin solid var(--border);
}

.policy-nav-item p {
  text-align: center;
  margin: 0;
  width: 100%;
}



.policy-nav-item:hover {
  background-color: var(--border);
}

.policy-nav-item-active {
  background-color: var(--border);
}

.policy {
  display: none;
}

.policy header{
  color: var(--white);
  margin-bottom: 10px;
  font-family: Medium;
}

.policy p{
  color: var(--white);
  font-family: LIght;
}

.policy ul {
  color: var(--white);
  margin-left: 20px;
}

.active {
  display: flex;
  width: 100%;
  margin-top: 5rem;
  flex-direction: column;
}



.about-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.about-header img {
  z-index: 1;
  position: absolute;
  opacity: 0.1;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.about-header {
  position: relative;
  display: flex;
  align-items: center;
  mask: linear-gradient(180deg, transparent, white 15%, white 85%, transparent);
  justify-content: center;
  width: 100%;
  height: 30rem;
  margin-bottom: 10rem;
  text-align: center;
  font-size: 2rem;
  color: var(--white);
  font-family: Bold;
}

.about-header {
  z-index: 2;
}

.about-history header {
  text-align: center;
  margin-bottom: 5rem;
}

.about-history {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 60rem;
}


.about-history-frame {
  position: relative;
  width: 35rem;
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: space-between;
  height: 25rem;
  transform: translateX(-10rem);
  transition: all ease-in-out 300ms;
  border-radius: var(--radius);
  overflow: hidden;
  border: thin solid var(--border);
  margin-bottom: 12rem;
}



.two {
  transform: translateX(10rem);
}

.about-history-frame-active {
  opacity: 1;
  transform: translateX(0);
}



.about-history-frame img {
  position: absolute;
  object-fit: cover;
  opacity: 0.2;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.about-history-frame h1 {
  font-family: Bold;
  font-size: 3rem;
  color: var(--white);
  margin: 1.5rem;
}

.history-right {
  text-align: right;
}

.history-text {
  margin: 1.5rem;
}

.history-text h2 {
  font-family: Medium;
  font-weight: none;
  font-size: 1.5rem;
  color: var(--white);
}

.history-text p {
  font-family: Light;
  font-size: 17px;
  color: var(--grey);
}

.about-goals {
  max-width: 70rem;
  margin-top: 8rem;
  width: 90%;
}

.about-goals header {
  text-align: center;
  margin-bottom: 5rem;
}

.goals-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.goal {
  color: var(--white);
  text-align: center;
  max-width: 25rem;
  margin: 1rem 5rem 5rem 5rem;

}

.goal svg  {
  transition: all ease-in-out 150ms;
}

.goal:hover svg {
  scale: 1.2;
}

.goal-text h1 {
  font-family: Medium;
  font-size: 1.5rem;
  color: var(--white);
  margin-bottom: 1rem;
}


.goal-text p {
  font-family: Light;
  font-size: 1rem;
  color: var(--grey);
}

@media  (max-width: 600px){
  .about-history-frame {
    width: 100%;
  }

  .about-history-frame h1 {
    font-family: Bold;
    font-size: 2.5rem;
    color: var(--white);
    margin: 1.5rem;
  }
  
  .history-text h2 {
    font-family: Medium;
    font-weight: none;
    font-size: 1.3rem;
    color: var(--white);
  }

  .history-text p {
    font-family: Light;
    font-size: 15px;
    color: var(--grey);
  }
}

.sitemap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;

  margin: 6rem auto;
}

.sitemap header {
  text-align: center;
  margin-bottom: 5rem;
}

.sitemap-lists {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 40rem;
  align-content: flex-start;
}

.sitemap-list {
  margin-bottom: 2rem;
  max-width: 15rem;
  width: fit-content;
  border-bottom: thin solid var(--border);
  margin-right: 2rem;
}

.sitemap-list ul {
  margin-bottom: 1rem;
}

.sitemap-list ul li {
  color: var(--white);
  margin-left: 20px;
  font-family: Light;
}

.sitemap-list h1 {
  color: var(--white);
  font-size: 1rem;
  margin-bottom: 10px;
  font-family: Medium;
}




@media  (max-width: 700px){

  .cheat-status-active {
    max-height: 5rem;
    min-height: 5rem;
  }
  

  }




footer {
  background-color: rgba(8, 8, 10, 1);
  padding: 2rem 0 1rem 0;
  border-top: var(--border) thin solid;
  margin: 25rem auto 0 auto;
}

.footer-links {
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 90%;
}

.footer-nav {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
}

.footer-hood {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 25rem;
}

.footer-hood h1 {
  color: var(--white);
  font-family: Bold;
  font-size: 15px;
}

.footer-hood p {
  color: var(--grey);
  font-family: Light;
  font-size: 14px;
  margin-top: 10px;
}

.footer-hood img {
  height: 15px;
  margin-right: 10px;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  
}

.footer-nav ul {
  width: fit-content;
  color: var(--white);
  list-style: none;
}

.footer-nav-title {
  font-family: Bold;
  font-size: 14px;
  margin-bottom: 10px;
}

.footer-nav ul li a{
  color: var(--grey);
  font-size: 14px;
  font-family: Medium;
}

.footer-contacts svg{
  margin-right: 10px;
  margin-top: 1rem;
}

.footer-products {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.footer-divide {
  width: 100%;
  margin-top: 1rem;
  background-color: var(--border);
  height: 1px;
}

.footer-products ul {
  width: fit-content;
  margin-top: 1rem;
  color: var(--white);
  list-style: none;
}

.footer-products ul:not(:last-child) {
  margin-right: 1rem;
}

.footer-nav-title {
  font-family: Bold;
  margin-bottom: 10px;
}

.footer-products ul li a{
  color: var(--grey);
  font-size: 14px;
  font-family: Medium;
}


.footer-credits {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: var(--grey);
}

.footer-sublinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 14px;
  width: 100%;
}

.sublinks {
  display: flex;
}


.payments {
  display: flex;
}

.payments svg {
  margin-right: 15px;
  color: var(--white);
}

.footer-sublinks p a{
  margin-right: 2rem;
  text-align: center;
  color: var(--white);
}

.footer-mobile {
  display: none;
}

.footer-sublinks-mobile {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 14px;
  width: 100%;
}

.footer-sublinks-mobile p a{
  margin-right: 2rem;
  text-align: center;
  color: var(--white);
}

.footer-divide-mobile {
  display: none;
  width: 100%;
  margin-top: 1rem;
  background-color: var(--border);
  height: 1px;
}

@media  (max-width: 1100px){

  .footer-sublinks {
    display: none;
  }
  .footer-sublinks-mobile  {
    display: flex;
  }

  .footer-divide-mobile {
    display: block;
  }
  .footer-sublinks-mobile p a{
    margin-right: 1rem;
    color: var(--white);
  }


}

@media  (max-width: 1000px){
  
  .footer-mobile {
    display: flex;
  }


  .footer-nav {

    margin-top: 2rem;
  }

  .footer-nav ul {
    display: none;
  }

}

@media  (max-width: 600px){
  
  .footer-nav {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .footer-hood {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  .footer-hood h1 {
    text-align: center;
    color: var(--white);
    font-family: Bold;
    font-size: 15px;
  }
  
  .footer-hood p {
    text-align: center;
    color: var(--grey);
    font-family: Light;
    font-size: 14px;
    margin-top: 10px;
  }
  

}